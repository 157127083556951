import React, { useState, useEffect } from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { useLocation } from '@reach/router'

import { useLunr } from '../../hooks/useLunr'

import Layout from '../../components/Layout'
import Breadcrumbs from '../../components/Breadcrumbs'
import PostSearchList from '../../components/PostSearchList'
import SEO from '../../components/SEO'

const Search = ({ location }) => {
  const l = useLocation()

  const data = useStaticQuery(graphql`
    query {
      allLocalSearchPosts {
        edges {
          node {
            index
            store
          }
        }
      }
    }
  `)

  const { index, store } = data.allLocalSearchPosts?.edges[0]?.node
  const [query, setQuery] = useState(null)
  const results = useLunr(query, index, store)

  const [loading, setLoading] = useState(true)

  useEffect(() => {
    setLoading(true)

    const url = new URLSearchParams(window.location.search)

    if (l.state) {
      setQuery(l.state.query)
    } else if (url) {
      setQuery(url.get('s'))
    }

    setTimeout(function () {
      setLoading(false)
    }, 1000)
  }, [location, query, results, l])

  const renderResults = (results) => {
    if (results.length === 0) {
      return (
        <div className="home-section" style={{ marginTop: 40 }}>
          <p className="search-no-results">
            Nenhum resultado encontrado para{' '}
            <span style={{ color: '#696969' }}>"{query}"</span>
          </p>
        </div>
      )
    }

    return <PostSearchList posts={results} />
  }

  if (loading) {
    return (
      <Layout>
        <div className="search-results">
          <Breadcrumbs post={query} isSearch={true} />

          <div className="infinite-loading-el" style={{ marginTop: 40 }}>
            <div className="loading-spinner">
              <h1>Carregando...</h1>
            </div>
          </div>
        </div>
      </Layout>
    )
  }

  return (
    <Layout>
      <SEO
        title="Busca"
        canonical={`${process.env.GATSBY_HOST_URL}/pt/search`}
        isAlternate={true}
        alternateUrl={`${process.env.GATSBY_HOST_URL}/en/search`}
      />

      <div className="search-results">
        <Breadcrumbs isSearch={true} post={query} />

        {renderResults(results.filter((r) => r.language === 'pt'))}
      </div>
    </Layout>
  )
}

export default Search
